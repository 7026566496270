/************ Custom Font ************/
@font-face {
	font-family: Centra;
	src: url('./assets/font/CentraNo2-Bold.ttf');
	font-weight: 700;
}
@font-face {
	font-family: Centra;
	src: url('./assets/font/CentraNo2-Medium.ttf');
	font-weight: 500;
}
@font-face {
	font-family: Centra;
	src: url('./assets/font/CentraNo2-Book.ttf');
	font-weight: 400;
}

/************ Default Css ************/
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 75px;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}

body {
  
  font-weight: 400;
  overflow-x: hidden;
  position: relative;
  background-color: #121212 !important;
  color: #fff !important;
  font-family: 'Centra', sans-serif !important;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
  line-height: normal;
}

p, a, li, button, ul {
  margin: 0;
  padding: 0;
  line-height: normal;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

img {
  width: 100%;
  height: auto;
}

button {
  border: 0;
  background-color: transparent;
}

input:focus, textarea:focus, select:focus {
  outline: none;
}

@media (min-width:1700px) {
	main .container {
		max-width: 100%;
		padding: 0 150px;
	}
}

p.success {
	color: green;
}

p.danger {
	color: red;
}
/************ Navbar Css ************/
.navbar-contact { /* Text color */ /* Font size */
  font-weight: bold; /* Bold text */
  cursor: pointer; /* Pointer cursor on hover */
   /* Space between the text and menu items */
  text-decoration: none; /* Remove underline */
  transition: color 0.3s ease; 
  font-weight: 400;
  color: #fff !important;
  letter-spacing: 0.8px;
  padding: 0 25px;
  font-size: 18px;
 /* Smooth transition for hover effect */
}



/* Remove underline specifically for HashLink */
.HashLink {
  text-decoration: none; /* Remove underline */
}

.navbar-contact:hover {
  color: white; /* More opaque background on hover */
  transform: scale(1.05);
  opacity: 1; /* Slightly increase size on hover */
}
nav.navbar {
  padding: 18px 0;
  width: 100%;
  overflow: hidden; 
  position: fixed;
  top: 0;
  z-index: 9999;
  transition:  0.32s ease-in-out;
}
.navbar-toggle {
  z-index: 9999; 
    /* Ensure the toggle button appears above other content */
}
.navbar-collapse {
  width: 100%; /* Ensure the collapsed menu fits within the viewport */
}

.navbar-toggler {
  margin-right: 30px;
}
@keyframes moveDroplets {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(50px, 50px);
  }
}
nav.navbar.scrolled {
  padding: 0px 0;
  background-color: #0C1D36;
}
nav.navbar a.navbar-brand {
    width: 4%;
}
nav.navbar .navbar-nav .nav-link.navbar-link {
  font-weight: 400;
  color: #fff !important;
  letter-spacing: 0.8px;
  padding: 0 25px;
  font-size: 18px;
  
}
nav.navbar .navbar-nav a.nav-link.navbar-link:hover,
nav.navbar .navbar-nav a.nav-link.navbar-link.active {
    opacity: 1;
    transform: scale(1.05);
}
span.navbar-text {
    display: flex;
    align-items: center;
}
.social-icon {
    display: inline-block;
    
}
.social-icon a {
    width: 42px;
    margin-top: 15px;
    height: 42px;
    background: rgba(217, 217, 217, 0.1);
    display: inline-flex;
    border-radius: 50%;
    margin-right: 10px;
    align-items: center;
    justify-content: center;
    line-height: 1;
    border: 1px solid rgba(255, 255, 255, 0.5);
}
.social-icon a::before {
    content: "";
    width: 42px;
    height: 42px;
    position: absolute;
    background-color: #ffffff;
    border-radius: 50%;
    transform: scale(0);
	transition: 0.3s ease-in-out;
}
.social-icon a:hover::before {
    transform: scale(1);
}
.social-icon a img {
	width: 40%;
	z-index: 1;
	transition: 0.3s ease-in-out;
}
.social-icon a:hover img {
	filter: brightness(0) saturate(100%) invert(0%) sepia(7%) saturate(98%) hue-rotate(346deg) brightness(95%) contrast(86%);
}
.navbar-text button {
  font-weight: 700;
  color: #fff;
  border: 1px solid #fff;
  padding: 18px 44px;
  font-size: 18px;
  margin-left: 10px;
	position: relative;
	background-color: transparent;
	transition: 0.3s ease-in-out;
}
.navbar-text button span {
  z-index: 1;
}
.navbar-text button::before {
  content: "";
  width: 0%;
  height: 100%;
  position: absolute;
  background-color: #fff;
  left: 0;
  top: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
}
.navbar-text button:hover {
  color: #121212;
}
.navbar-text button:hover::before {
	content: "";
	width: 100%;
	height: 100%;
	position: absolute;
}
nav.navbar .navbar-toggler:active,
nav.navbar .navbar-toggler:focus {
	outline: none;
    box-shadow: none;
}
nav.navbar .navbar-toggler-icon {
  width: 24px;
  height: 17px;

  
  background-image: none;
  position: relative;
  border-bottom: 2px solid #fff;
  transition: all 300ms linear;
  top: -2px;
}
nav.navbar .navbar-toggler-icon:focus {
	border-bottom: 2px solid #fff;
}
nav.navbar .navbar-toggler-icon:after,
nav.navbar .navbar-toggler-icon:before {
	width: 24px;
	position: absolute;
	height: 2px;
	background-color: #fff;
	top: 0;
	left: 0;
	content: '';
	z-index: 2;
	transition: all 300ms linear;
}
nav.navbar .navbar-toggler-icon:after {
	top: 8px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
	transform: rotate(45deg);
	background-color: #fff;
	height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
	transform: translateY(8px) rotate(-45deg);
	background-color: #fff;
	height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
	border-color: transparent;
}

/************ Banner Css ************/
.droplets {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
}

.droplets {
  position: absolute;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: rgba(0, 123, 255, 0.7); /* Blue droplet */
  opacity: 0;
  animation: fall 6s linear infinite;
}

@keyframes fall {
  0% {
    opacity: 1;
    transform: translateY(0) translateX(0);
  }
  50% {
    opacity: 0.7;
    transform: translateY(50vh) translateX(10vw); /* Randomize horizontal position */
  }
  100% {
    opacity: 0;
    transform: translateY(100vh) translateX(0); /* Make droplets fall off-screen */
  }
}
.banner {
  margin-top: 0;
  padding: 140px 0 20px 0;
  background-color: #F4F4F4;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.banner span.tagline {
 
  justify-content: center;
  align-items: center;
  
}
.banner .tagline {
  font-weight: 700;
  letter-spacing: 0.8px;
  
  padding: 8px 10px;
  background: linear-gradient(135deg, rgba(15, 15, 60, 0.9) 0%, rgba(0, 20, 40, 0.9) 50%, rgba(0, 70, 100, 0.9) 100%);
  border: 1px solid rgba(255, 255, 255, 0.5);
  font-size: 20px;
  margin-bottom: 16px;
  display: inline-block;
}
.resume-button {
  font-weight: 700;
  letter-spacing: 0.8px;
  
 
  background: linear-gradient(135deg, rgba(15, 15, 60, 0.9) 0%, rgba(0, 20, 40, 0.9) 50%, rgba(0, 70, 100, 0.9) 100%);
  border: 1px solid rgba(255, 255, 255, 0.5);
  font-size: 20px;
  margin-bottom: 16px;
  padding: 8px 10px;
  color: #fff;
  text-decoration: none;
  display: inline-block;
  transition: background 0.3s ease, color 0.3s ease;
  
}
.resume-container {
  display: flex;
  justify-content: center;
  align-items: center;
  
}
.resume-button:hover {
  background: linear-gradient(135deg, rgba(15, 15, 60, 1) 0%, rgba(0, 20, 40, 1) 50%, rgba(0, 70, 100, 1) 100%);
  color: #d3d3d3;
}
.banner h1 {
  font-size: 65px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1;
  color: #4995B9;
  margin-bottom: 20px;
  display: block;
}
.banner p {
  color: #E96193;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  width: 96%;
}
.banner button {
  color: #fff;
  font-weight: 700;
  font-size: 20px;
  margin-top: 60px;
  letter-spacing: 0.8px;
  display: flex;
  align-items: center;
}
.banner button svg {
  font-size: 25px;
  margin-left: 10px;
  transition: 0.3s ease-in-out;
  line-height: 1;
}
.banner button:hover svg {
  margin-left: 25px;
}

@keyframes updown {
    0% {
        transform: translateY(-20px);
    }
    50% {
        transform: translateY(20px);
    }
    100% {
        transform: translateY(-20px);
    }
}
.txt-rotate > .wrap {
  border-right: 0.08em solid #666;
}

/************ Skills Css ************/
.skill {
  padding: 0 0 50px 0;
  position: relative;
}
.skill-bx {
  background: #F5F5F5;
  border-radius: 64px;
  text-align: center;
  padding: 60px 50px;
  margin-top: -60px;
}
.skill h2 {
	font-size: 45px;
	font-weight: 700;
}
.skill p {
    color: #333333;
    font-size: 18px;
    letter-spacing: 0.8px;
    line-height: 1.5em;
    margin: 14px 0 75px 0;
}
.skill-slider {
  width: 80%;
  margin: 0 auto;
  position: relative;
}
.skill-slider .item img {
  width: 50%;
  margin: 0 auto 15px auto;
}
.background-image-left {
 
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: -4;
}

/************ Projects Css ************/
.project {
  padding: 50px 0;
  position: relative;
  background-color: #CECEC2;
}
.project h2 {
	font-size: 45px;
	font-weight: 700;
  text-align: center;
}
.project p {
  color: #B8B8B8;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  margin: 14px auto 30px auto;
  text-align: center;
  width: 86%;
}
.project .nav.nav-pills {
  width: 72%;
  margin: 0 auto;
  border-radius: 50px;
  background-color: black;
  overflow: hidden;
}
.project .nav.nav-pills .nav-item {
  width: 100%;
  
}
.project .nav.nav-pills .nav-link {
  background-color: transparent;
  border-radius: 0;
  padding: 17px 0;
  color: #fff;
  width: 100%;
  font-size: 17px;
  letter-spacing: 0.8px;
  font-weight: 500;
  position: relative;
  transition: 0.3s ease-in-out;
  text-align: center;
  z-index: 0;
}
.project .nav.nav-pills .nav-link::before {
    content: "";
    position: absolute;
    width: 0;
    height: 100%;
    background: linear-gradient(135deg, rgba(15, 15, 60, 0.9) 0%, rgba(0, 20, 40, 0.9) 50%, rgba(0, 70, 100, 0.9) 100%);
    top: 0;
    left: 0;
    z-index: -1;
    transition: 0.3s ease-in-out;
}
.project .nav.nav-pills .nav-link.active::before {
    width: 100% !important;
}
.project .nav.nav-pills .nav-link.active {
    border: 1px solid rgba(255, 255, 255, 1);
    
}
.nav-link#projects-tabs-tab-first {
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 55px 0px 0px 55px;
}
.nav-link#projects-tabs-tab-second {
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}
.nav-link#projects-tabs-tab-third {
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 0 55px 55px 0;
}
.proj-imgbx {
  position: relative;
  border-radius: 30px;
  overflow: hidden;
  aspect-ratio: 16 / 9;
  margin-bottom: 24px;
  width: 100%;
  height: 200px; /* Fixed height */
  object-fit: cover; /* Ensures consistent aspect ratio */
 
}
.proj-imgbx img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the image covers the container without distortion */
}

.proj-imgbx::before {
  content: "";
  background: linear-gradient(135deg, rgba(15, 15, 60, 0.9) 0%, rgba(0, 20, 40, 0.9) 50%, rgba(0, 70, 100, 0.9) 100%);
  opacity: 0.70;
  position: absolute;
  width: 100%;
  height: 100%;
  transition: 0.4s ease-in-out;
}
.proj-imgbx:hover::before {
  height: 100%;
}
.proj-txtx {
  position: absolute;
  text-align: center;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.5s ease-in-out;
  opacity: 0;
  width: 100%;
}
.proj-imgbx:hover .proj-txtx {
  top: 50%;
  opacity: 1;
}
.proj-txtx h4 {
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1.1em;
}
.proj-txtx span {
  font-style: italic;
  font-weight: 400;
  font-size: 15px;
  letter-spacing: 0.8px;
}
.background-image-right {
  top: 20%;
  position: absolute;
  bottom: 0;
  width: 35%;
  right: 0;
  z-index: -4;
}

/************ Projects Css ************/

.contact {
  background: linear-gradient(135deg, rgba(15, 15, 60, 0.9) 0%, rgba(0, 20, 40, 0.9) 50%, rgba(0, 70, 100, 0.9) 100%);
  padding: 60px 0 200px 0;
}
.contact img {
  width: 92%;
}
.contact h2 {
	font-size: 45px;
	font-weight: 700;
  margin-bottom: 30px;
}

.contact form input, .contact form textarea {
  width: 100%;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 20px;
  color: #fff;
  margin: 0 0 8px 0;
  padding: 18px 26px;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.8px;
  transition: 0.3s ease-in-out;
}
.contact form input:focus, .contact form textarea:focus {
  background: rgba(255, 255, 255, 1);
  color: #121212;
}
h1 span:hover {
  text-decoration: underline;
  cursor: pointer;
  filter: brightness(1.2);
}
.contact form input::placeholder, .contact form textarea::placeholder {
  font-size: 16px;
  font-weight: 400;
  color: #fff;
}
.contact form input:focus::placeholder, .contact form textarea:focus::placeholder {
  color: #121212;
  opacity: 0.8;
}
.contact form button {
  font-weight: 700;
  color: #000;
  background-color: #fff;
  padding: 14px 48px;
  font-size: 18px;
  margin-top: 25px;
  border-radius: 0;
  position: relative;
  transition: 0.3s ease-in-out;
}
.contact form button span {
  z-index: 1;
  position: relative;
}
.contact form button:hover {
  color: #fff;
}
.contact form button::before {
  content: "";
  
  width: 0;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  transition: 0.3s ease-in-out;
}
.contact form button:hover::before {
  width: 100%;
}

/************ Footer Css ************/
.footer {
  padding: 0 0 50px 0;
  /* background-image: url('./assets/img/dark7.jpg'); */
  background-color: #0C1D36;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
.newsletter-bx {
  background: #FFFFFF;
  border-radius: 55px;
  color: #fff;
  padding: 85px 125px;
  margin-bottom: 80px;
  margin-top: -122px;
}
.newsletter-bx h3 {
  font-weight: 700;
  letter-spacing: 0.5px;
  line-height: 1.2em;
}
.new-email-bx {
  background: #fff;
  padding: 5px;
  border-radius: 20px;
  position: relative;
  z-index: 0;
  display: flex;
  align-items: center;
}
.new-email-bx::before {
  content: "";
  background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
  border-radius: 20px;
  position: absolute;
  z-index: -1;
  top: -1px;
  left: -1px;
  bottom: -1px;
  right: -1px;
}
.new-email-bx::after {
  content: "";
  background: #fff;
  border-radius: 20px;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.new-email-bx input {
  width: 100%;
  color: #fff;
  font-weight: 500;
  background: #fff;
  border: 0;
  padding: 0 15px;
}
.new-email-bx button {
  background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
  padding: 20px 65px;
  color: #fff;
  font-weight: 500;
  letter-spacing: 0.5px;
  border-radius: 18px;
}
.footer {
  font-size: 14px;
}

.footer-heading {
  font-size: 16px;
  margin-bottom: 15px;
}

.social-icons {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  justify-content: center;
}

.social-item {
  display: flex;
  align-items: center;
  gap: 10px;
}

.social-icon {
  width: 25px;
  height: 25px;
  filter: brightness(0) invert(1); /* Makes the icon white */
}

/* Media Queries */
@media (max-width: 768px) {
  .social-icons {
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
}

@media (max-width: 576px) {
  .footer {
    font-size: 12px;
    padding: 10px 0;
  }

  .social-icon {
    width: 20px;
    height: 20px;
  }

  .footer-heading {
    font-size: 14px;
  }
}
.footer img {
  width: 26%;
}
/* Add this CSS to your style file or inside a <style> tag */
.social-icon-img {
  filter: brightness(0) saturate(100%) invert(51%) sepia(99%) saturate(2715%) hue-rotate(179deg) brightness(100%) contrast(100%);
}

.social-icon-img:hover {
  transform: scale(1.2);
  filter: brightness(1.2) saturate(1.5);
}
.footer p {
  font-weight: 400;
  font-size: 14px;
  color: #B8B8B8;
  letter-spacing: 0.5px;
  margin-top: 20px;
}
