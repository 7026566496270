.contact {
    padding: 50px 20px;
    background-color: transparent;
  }
  
  /* Tabs styling */
  .tabs button {
    padding: 10px 20px;
    font-size: 16px;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .tabs button.primary {
    background-color: #007bff;
    color: white;
  }
  
  .tabs button.secondary {
    background-color: #f0f0f0;
    color: #333;
  }
  
  .tabs button:hover {
    opacity: 0.9;
  }
  
  /* Form styling */
  form {
    background-color: transparent;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  input,
  select,
  textarea {
    width: 100%;
    padding: 12px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 8px;
    margin-bottom: 15px;
    transition: border-color 0.3s;
  }
  
  input:focus,
  select:focus,
  textarea:focus {
    border-color: #007bff;
    outline: none;
  }
  
  /* Button styling */
  button[type="submit"] {
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 8px;
    padding: 12px 20px;
    font-size: 18px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  button[type="submit"]:hover {
    background-color: #0056b3;
  }
  
  /* Status message styling */
  .status-message {
    margin-top: 20px;
    padding: 10px;
    border-radius: 5px;
    font-size: 16px;
    text-align: center;
  }
  
  .status-message.success {
    background-color: #d4edda;
    color: #155724;
  }
  
  .status-message.error {
    background-color: #f8d7da;
    color: #721c24;
  }
  
  /* Responsive styling */
  @media (max-width: 768px) {
    .tabs {
      flex-direction: column;
      gap: 10px;
    }
  
    input,
    select,
    textarea {
      font-size: 14px;
    }
  
    button[type="submit"] {
      font-size: 16px;
    }
  }